<template>
  <content-template title="MPA">
    <content-container
      title="UBS Quarter End Period"
      description="Select a date range to set the window for the quarter-end period for UBS."
      class="quarter-end"
    >
      <fade-transition>
        <mds-loader v-if="quarterEndWindowDataLoading" />
        <mds-form
          v-else
          class="quarter-end__form"
          @submit.native.prevent="submitQuarterEndWindow"
        >
          <mds-fieldset
            horizontal
            class="quarter-end__date-picker-container"
          >
            <mds-date-picker
              v-model="selectedQuarterEndDateRange"
              label="Date Range:"
              format="m/d/Y"
              mode="range"
              class="quarter-end__date-picker"
              :disabled="disableDatepicker"
            />
            <mds-button
              v-if="quarterEndWindowEnabled && !editingQuarterEndWindow"
              variation="flat"
              type="button"
              class="quarter-end__date-picker-edit"
              :disabled="disableTestView"
              @click="editQuarterEndWindow"
            >
              Edit
            </mds-button>
          </mds-fieldset>
          <mds-button-container>
            <mds-button
              v-if="!quarterEndWindowEnabled || editingQuarterEndWindow"
              variation="primary"
              :disabled="!quarterEndWindowCanBeSubmitted"
            >
              Submit
            </mds-button>
            <mds-button
              v-if="quarterEndWindowEnabled || editingQuarterEndWindow"
              type="button"
              :disabled="disableTestView"
              @click="openQuarterEndCancelConfiramationDialog"
            >
              Cancel Quarter End Period
            </mds-button>
          </mds-button-container>
        </mds-form>
      </fade-transition>
      <mds-dialog
        v-model="quarterEndConfiramationDialogOpen"
        width="500px"
        action-required
      >
        Are you sure you want to cancel?
        <template v-slot:mds-dialog-actions-right>
          <mds-button-container right-aligned>
            <mds-button
              variation="secondary"
              type="button"
              @click="cancelQuarterEndWindow"
            >
              Yes
            </mds-button>
            <mds-button
              variation="primary"
              type="button"
              @click="closeQuarterEndCancelConfirmationDialog"
            >
              No
            </mds-button>
          </mds-button-container>
        </template>
      </mds-dialog>
    </content-container>
  </content-template>
</template>

<script>
import { MdsButton, MdsButtonContainer } from '@mds/button'
import ContentContainer from '../../components/ContentContainer/ContentContainer'
import ContentTemplate from '../../components/ContentTemplate/ContentTemplate'
import MdsDatePicker from '../../components/Form/MdsDatePicker/MdsDatePicker'
import FadeTransition from '../../components/Transitions/FadeTransition'
import MdsDialog from '@mds/dialog'
import MdsFieldset from '@mds/fieldset'
import MdsForm from '@mds/form'
import MdsLoader from '@mds/loader'
import { formatDateFromIsoString, formatIsoFromDateString } from '../../../shared/utils'
import { DATE_FORMAT } from '../../../shared/constants/dates'
import { GET_QUARTER_END_WINDOW } from '../../graqhql/queries'
import { CREATE_QUARTER_END_WINDOW, UPDATE_QUARTER_END_WINDOW } from '../../graqhql/mutations'
import RoleAccess from '../../services/role-access-service'
import { RadarViews } from '../../../shared/constants/radar-views'

// Hard-coded. UBS is currently the only fiduciary where we need to update the quarter end range.
const SELECTED_FIDUCIARY_CODE = 'UBS'
const INITIAL_SELECTED_QUARTER_END_DATE_RANGE = [new Date(), new Date()]

export default {
  components: {
    ContentContainer,
    ContentTemplate,
    MdsDatePicker,
    MdsButton,
    MdsDialog,
    MdsLoader,
    MdsForm,
    MdsFieldset,
    MdsButtonContainer,
    FadeTransition,
  },
  apollo: {
    quarterEndWindowData: {
      query: GET_QUARTER_END_WINDOW,
      variables: {
        fiduciaryCode: SELECTED_FIDUCIARY_CODE,
      },
      update: data => data.quarterEndWindow,
    },
  },
  data () {
    return {
      quarterEndWindowData: null,
      quarterEndConfiramationDialogOpen: false,
      editingQuarterEndWindow: false,
      selectedQuarterEndDateRange: INITIAL_SELECTED_QUARTER_END_DATE_RANGE,
      disableEdit: RoleAccess.disableEdit,
      selectedView: RoleAccess.selectedView,
    }
  },
  computed: {
    quarterEndWindowDataLoading () {
      return this.$apollo.queries.quarterEndWindowData.loading
    },
    quarterEndWindowDataAvailable () {
      return !!this.quarterEndWindowData
    },
    quarterEndWindowEnabled () {
      return !!(this.quarterEndWindowDataAvailable &&
        this.quarterEndWindowData.enabled &&
        this.quarterEndWindowData.start &&
        this.quarterEndWindowData.end)
    },
    quarterEndWindow () {
      return this.quarterEndWindowDataAvailable && this.quarterEndWindowEnabled
        ? [
          formatDateFromIsoString(this.quarterEndWindowData.start, DATE_FORMAT),
          formatDateFromIsoString(this.quarterEndWindowData.end, DATE_FORMAT),
        ]
        : []
    },
    quarterEndWindowCanBeSubmitted () {
      return this.selectedQuarterEndDateRange.length === 2 &&
        this.selectedQuarterEndDateRange[0] < this.selectedQuarterEndDateRange[1]
    },
    disableTestView () {
      return (this.selectedView === RadarViews.TEST) || this.disableEdit
    },
    disableDatepicker () {
      return this.disableTestView || (this.quarterEndWindowEnabled && !this.editingQuarterEndWindow)
    },
  },
  watch: {
    quarterEndWindow: {
      immediate: true,
      handler (range) {
        this.selectedQuarterEndDateRange = range
      },
    },
  },
  methods: {
    updateQuarterEndWindow ({ start, end, enabled }) {
      // No need to directly write to cache since we are returning a unique ID.
      // Apollo is smart enough to handle updating the cache with this mutation's response.
      return this.$apollo.mutate({
        mutation: UPDATE_QUARTER_END_WINDOW,
        variables: {
          options: {
            id: SELECTED_FIDUCIARY_CODE,
            fiduciaryCode: SELECTED_FIDUCIARY_CODE,
            enabled,
            start,
            end,
          },
        },
      })
    },
    createQuarterEndWindow ({ start, end, enabled }) {
      return this.$apollo.mutate({
        mutation: CREATE_QUARTER_END_WINDOW,
        variables: {
          options: {
            id: SELECTED_FIDUCIARY_CODE,
            fiduciaryCode: SELECTED_FIDUCIARY_CODE,
            enabled,
            start,
            end,
          },
        },
        update (store, { data: { createQuarterEndWindow } }) {
          // Because no ID was returned from the initial query, Apollo is unable to manually update the cache itself.
          // Instead, we need to do that ourselves.
          store.writeQuery({
            query: GET_QUARTER_END_WINDOW,
            variables: { fiduciaryCode: SELECTED_FIDUCIARY_CODE },
            data: { quarterEndWindow: createQuarterEndWindow },
          })
        },
      })
    },
    async submitQuarterEndWindow () {
      try {
        const start = formatIsoFromDateString(this.selectedQuarterEndDateRange[0], DATE_FORMAT)
        const end = formatIsoFromDateString(this.selectedQuarterEndDateRange[1], DATE_FORMAT)
        this.quarterEndWindowData
          ? await this.updateQuarterEndWindow({ start, end, enabled: true })
          : await this.createQuarterEndWindow({ start, end, enabled: true })
        this.$notifications.success('Your changes have been saved')
        this.editingQuarterEndWindow = false
      } catch (error) {
        // Temporary. Still need to define how we handle errors on the UI.
        this.$notifications.error('There was an issue saving your changes. Please try again.')
      }
    },
    async cancelQuarterEndWindow () {
      try {
        await this.updateQuarterEndWindow({ start: null, end: null, enabled: false })
        this.closeQuarterEndCancelConfirmationDialog()
        this.$notifications.success('Your changes have been saved')
        this.editingQuarterEndWindow = false
      } catch (error) {
        // Temporary. Still need to define how we handle errors on the UI.
        this.closeQuarterEndCancelConfirmationDialog()
        this.$notifications.error('There was an issue saving your changes. Please try again.')
      }
    },
    editQuarterEndWindow () {
      this.editingQuarterEndWindow = true
    },
    openQuarterEndCancelConfiramationDialog () {
      this.quarterEndConfiramationDialogOpen = true
    },
    closeQuarterEndCancelConfirmationDialog () {
      this.quarterEndConfiramationDialogOpen = false
    },
  },
}
</script>

<style lang="scss">
.quarter-end__date-picker-container {
  display: inline-flex;
}

.quarter-end__date-picker {
  width: 220px;
}

.quarter-end__date-picker-edit {
  align-self: flex-end;
  margin-bottom: $mds-space-2-and-a-half-x; // Matching margin of date picker input
}
</style>
